@import (reference) '../../style/themes/index';

@table-prefix-cls: ~'@{ant-prefix}-table';

.table-size(@size, @padding-vertical, @padding-horizontal, @font-size) {
  .@{table-prefix-cls}.@{table-prefix-cls}-@{size} {
    font-size: @font-size;

    .@{table-prefix-cls}-title,
    .@{table-prefix-cls}-footer,
    .@{table-prefix-cls}-thead > tr > th,
    .@{table-prefix-cls}-tbody > tr > td,
    tfoot > tr > th,
    tfoot > tr > td {
      padding: @padding-vertical @padding-horizontal;
    }

    .@{table-prefix-cls}-filter-trigger {
      margin-right: -(@padding-horizontal / 2);
    }

    .@{table-prefix-cls}-expanded-row-fixed {
      margin: -@padding-vertical -@padding-horizontal;
    }

    .@{table-prefix-cls}-tbody {
      // ========================= Nest Table ===========================
      .@{table-prefix-cls}-wrapper:only-child {
        .@{table-prefix-cls} {
          margin: -@padding-vertical -@padding-horizontal -@padding-vertical (@padding-horizontal +
                ceil((@font-size-sm * 1.4)));
        }
      }
    }
  }
}

// ================================================================
// =                            Middle                            =
// ================================================================
.table-size(~'middle', @table-padding-vertical-md, @table-padding-horizontal-md, @table-font-size-md);

// ================================================================
// =                            Small                             =
// ================================================================
.table-size(~'small', @table-padding-vertical-sm, @table-padding-horizontal-sm, @table-font-size-sm);
